export default {
  selectors: {
    imageCarousel: '.corp-hero-carousel__image-swiper',
    teaserContentCarousel: '.corp-hero-carousel__teaser__content-swiper',
    customSwiperController: '.custom-swiper-controller',
  },
  classes: {
    strip: 'custom-swiper-controller-strip',
    activeStrip: 'custom-swiper-controller-strip-active',
    progressWrapper: 'custom-swiper-controller-progress-wrapper',
    progress: 'custom-swiper-controller-progress',
  },
  swiper: {
    speed: 600,
  },
};
