import { register } from '@netcentric/component-loader';
import config from "./corp-hero-carousel.config";

class CorpHeroCarousel {
  constructor(el, params) {
    this.el = el;
    this.config = { ...config, ...params };
    this.init();

  }

  init() {
    if (!this.config.editMode) {
      this.initCarousel();
    }
  }

  initCarousel() {
    this.appendStyleVariable();
    const imageSwiper = new window.Swiper(`${this.config.selectors.imageCarousel}`, {
      slidesPerView: 1,
      loop: true,
    });

    const teaserSwiper = new window.Swiper(`${this.config.selectors.teaserContentCarousel}`, {
      slidesPerView: 1,
      loop: true,
      init: false,
      speed: this.config.swiper.speed,
      autoplay: this.config.autoplay ? {
        delay: this.config.delay,
        pauseOnMouseEnter: this.config.pauseOnHover,
      } : false,
      pagination: {
          el: this.config.selectors.customSwiperController,
          bulletClass: this.config.classes.strip,
          bulletActiveClass: this.config.classes.activeStrip,
          clickable: true,
          renderBullet: (index, className) => `<span class="${className}">
                                                  <span class="${this.config.classes.progressWrapper}">
                                                    <span class="${this.config.classes.progress}">
                                                    </span>
                                                  </span>
                                                </span>`,
      },
    });

    teaserSwiper.on('init', () => {
      teaserSwiper.pagination.bullets[0].classList.remove(this.config.classes.activeStrip);
    });
    teaserSwiper.init();
    imageSwiper.controller.control = teaserSwiper;
    teaserSwiper.controller.control = imageSwiper;
  }

  appendStyleVariable() {
    const animationSpeed = this.config.autoplay ? (this.config.swiper.speed + this.config.delay) * 0.001 : '0';
    this.el.style.setProperty('--swiper-progress-animation-speed', `${animationSpeed}s`);
  }
}

register({ CorpHeroCarousel });
